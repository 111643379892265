import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import closeImg from "../../assets/ContactUs/close.png";
import emailjs from "emailjs-com";
import axios from "axios";

const ContactUs = () => {
  const [t, i18n] = useTranslation(); //translation hook
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [acres, setAcres] = useState("");
  const [role, setRole] = useState("");
  const [crops, setCrops] = useState("");
  const form = useRef(null);

  //  const getSelectedJob = () => {
  //    var name = document.getElementById("job");
  //    var uRole = name.options[name.selectedIndex].text;
  //    setRole(uRole);
  //    // var value = name.value;
  //  };

  const getSelectedJob = (event) => {
    setRole(event.target.value); // Directly set the selected value
  };


   const addContact = (e) => {
     e.preventDefault();
     //emailjs library method to send email from client side
     emailjs
       .sendForm(
         "service_hn7s3jk",
         "template_obm531g",
         form.current,
         "eNn8vygAinkMUa2Fx"
       )
       .then((res) => {
         console.log(res.text);
         form.current.reset();
       })
       .catch((e) => console.log(e));
     axios
       .post(`contact`, {
         // .post(`contact`, {
         firstName: firstName,
         lastName: lastName,
         email: email,
         phoneNumber: phone,
         acres: acres,
         userRole: role,
         crop: crops,
       })
       .then((response) => {
         console.log(response.data);
       });
     setFirstName("");
     setLastName("");
     setEmail("");
     setRole("");
     // Reset the selected index to the first option (empty value)
     document.getElementById("job").selectedIndex = 0;
     setPhone("");
     setAcres("");
     // Reset the checkbox values after submission
     document.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
       checkbox.checked = false;
     });
     setCrops("");
   };

   const handleCrops = (e) => {
     const { value, checked } = e.target;
     if (checked) {
       // Add the crop to the selectedCrops string
       setCrops((prevSelected) =>
         prevSelected ? `${prevSelected}, ${value}` : value
       );
     } else {
       // Remove the crop from the selectedCrops string
       setCrops((prevSelected) =>
         prevSelected.replace(new RegExp(`${value},?`), "")
       );
     }
   };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>
          {t("Contact ")}
          <span>{t("Us")}</span>
          <span>.</span>
        </h2>
        <Link to="/">
          <img src={closeImg} alt="" className={styles.close} />
        </Link>
      </div>
      <form className={styles.form} onSubmit={addContact} ref={form}>
        <fieldset className={styles.row}>
          <input
            type="text"
            name="firstName"
            placeholder={t("First Name")}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className={styles.input}
          />
          <input
            type="text"
            name="lastName"
            placeholder={t("Last Name")}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className={styles.input}
          />
        </fieldset>

        <fieldset className={styles.row}>
          <input
            type="email"
            name="email"
            placeholder={t("Email")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={styles.input}
          />
          <input
            type="text"
            name="phone"
            placeholder={t("Phone Number")}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            className={styles.input}
          />
        </fieldset>

        <fieldset className={styles.row}>
          <select
            id="job"
            name="role"
            value={role}
            onChange={getSelectedJob}
            className={styles.input}
          >
            <option value="" hidden>
              {t("I am a")}
            </option>
            <option value="Agronomy Advisor">{t("Agronomy Advisor")}</option>
            <option value="Ag Retailer">{t("Ag Retailer")}</option>
            <option value="Grower">{t("Grower")}</option>
            <option value="Other">{t("Other")}</option>
          </select>
          <input
            type="number"
            name="acres"
            placeholder={t("If you are a grower, How many acres do you farm?")}
            value={acres}
            onChange={(e) => setAcres(e.target.value)}
            className={styles.input}
          />
        </fieldset>

        <fieldset className={styles.crops}>
          <legend>{t("Which crops do you farm?")}</legend>
          {[
            t("Mango"),
            t("Palm"),
            t("Apple"),
            t("Corn"),
            t("Grape"),
            t("Pepper"),
            t("Strawberry"),
            t("Tomato"),
            t("Citrus"),
            t("Other"),
          ].map((crop) => (
            <label key={crop} className={styles.checkboxLabel}>
              <input
                type="checkbox"
                name="crops"
                value={crop}
                onChange={(e) => handleCrops(e)}
              />
              {crop}
            </label>
          ))}
        </fieldset>

        <button type="submit" className={styles.submitButton}>
          {t("Schedule A Demo")}
        </button>
      </form>
    </div>
  );
};
export default ContactUs;
